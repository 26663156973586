<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer>
    <template #modal-header-close><wbr/></template>
    <dimmer :active="submitting">
      <div v-if="hasFileInput">
        <file-input
          data-test="container-file-upload"
          headerText="Add a CSV/Excel File"
          accept=".csv,.xlsx"
          :noticeText="note"
          @fileChange="file => selectedFile = file"></file-input>
        <hr>
      </div>

      <div>
        <p
          v-html="confirmText"
          data-test="txt-confirm-message"
          class="lead text-center"
        >
        </p>
      </div>
      <div class="d-flex justify-content-center actions">
        <button
          data-test="btn-back"
          class="btn btn-lg btn-link"
          @click.prevent="showModal = false">
          Back
        </button>
        <button
          data-test="btn-yes"
          class="btn btn-lg"
          :class="isDanger ? 'btn-danger' : 'btn-primary'"
          :disabled="hasFileInput && !selectedFile"
          @click="confirm">
          Yes
        </button>
      </div>
    </dimmer>
  </b-modal>
</template>

<script>
import FileInput from '@/components/FileInput';
import modal from '@/mixins/modal';

export default {
  name: 'ConfirmModal',
  components: {
    FileInput,
  },
  mixins: [modal],
  props: {
    submitting: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'Are you sure?',
    },
    hasFileInput: {
      type: Boolean,
      default: false,
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
    note: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedFile: [],
      isFileUploading: false,
    };
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.selectedFile);
    },
  },
};
</script>

<style scoped>
.actions {
  margin-top: 2rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

p.lead {
  font-size: 1.5rem;
}

::v-deep .modal-header {
  border-bottom: 0;
}
</style>
